import React, { useEffect, useState } from 'react';
import Wrapper from './styles';
import Container from '../../../components/container/Container';
import { Alert, App, Card, Form, InputNumber, Space, Typography } from 'antd';
import { useTranslation } from '../../../hooks/use-translation';
import { UiButton } from '../../../ui/button/UiButton';
import { useForm, useWatch } from 'antd/es/form/Form';
import { createWithdrawRequest } from '../../../api/withdraw';
import { getBalances } from '../../../api/wallet/wallet';
import { Balances } from '../../../api/wallet/types';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { Link } from 'react-router-dom';
import { getRoute } from '../../../utils/browser';

const { Title } = Typography;
const MIN_WITHDRAW_AMOUNT = 5;

export function WithdrawPage() {
    const { translate } = useTranslation();
    const [availableBalance, setAvailableBalance] = useState<Balances>();
    const [form] = useForm();
    const amountToWithdraw = useWatch('amount', form);
    const { message } = App.useApp();
    const [isProfileIncomplete, setIsProfileIncomplete] = useState(false);
    const [, setIsLoading] = useState(true);
    const userProfile = useSelector((root: RootState) => root.profile.userProfile);

    useEffect(() => {
        getUserBalance();
    }, []);

    useEffect(() => {
        if (!userProfile) return;
        setIsProfileIncomplete(userProfile.loaded && !(userProfile.entrepreneurName && userProfile.entrepreneurAccount));
    }, [userProfile]);

    useEffect(() => {
        if (!availableBalance) return;
        form.setFieldValue('amount', availableBalance.balance);
    }, [availableBalance]);

    async function getUserBalance() {
        setIsLoading(true);
        const balances = await getBalances();
        if (balances) {
            setAvailableBalance(balances);
        }
        setIsLoading(false);
    }

    async function makeRequest() {
        const amount = form.getFieldValue('amount');
        if (amount < MIN_WITHDRAW_AMOUNT) {
            message.error(`${MIN_WITHDRAW_AMOUNT} EUR is minimum amount to withdraw`);
            return;
        }
        if (isProfileIncomplete) {
            message.error('Please check profile to fill up required info');
            return;
        }
        try {
            await createWithdrawRequest(parseFloat(amount));
            message.success(translate('Withdraw request has been created', 'ui.withdraw'));
            await getUserBalance();
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <Wrapper>
            <Container>
                <Card>
                    <Title level={2}>{translate('Withdraw', 'ui.withdraw')}</Title>
                    {isProfileIncomplete && (
                        <Alert
                            message={
                                <Space direction="vertical">
                                    <div>Your profile is incomplete</div>
                                    <div>
                                        Please navigate to{' '}
                                        <strong>
                                            <Link to={getRoute('settings/profile')}>Profile Page</Link>
                                        </strong>{' '}
                                        to fill required fields
                                    </div>
                                    <div>
                                        Required fields: <strong>Enterpreneur name and account</strong>
                                    </div>
                                </Space>
                            }
                            type="error"
                        />
                    )}
                    <Form form={form} initialValues={{ amount: availableBalance?.balance ?? 0 }}>
                        {parseInt(amountToWithdraw, 10) < MIN_WITHDRAW_AMOUNT && (
                            <Alert message={translate('minimum amount alert', 'ui.withdraw')} type="warning" closable={false} />
                        )}
                        {availableBalance && (
                            <Title level={3}>
                                {translate('Available balance to withdraw:', 'ui.withdraw')} {availableBalance.balance} EUR
                            </Title>
                        )}
                        <Form.Item
                            name="amount"
                            rules={[{ required: true, message: translate('Amount field is required', 'ui.withdraw') }]}
                        >
                            <InputNumber size="large" inputMode="numeric" />
                        </Form.Item>
                        <UiButton type="primary" size="large" onClick={makeRequest}>
                            {translate('Create withdraw request', 'ui.withdraw')}
                        </UiButton>
                    </Form>
                </Card>
            </Container>
        </Wrapper>
    );
}
