import React, { useEffect, useState } from 'react';
import { authWithSocial } from '../../../api/auth';
import { FacebookLoginData } from '../../../types/social';
import { useDispatch } from 'react-redux';
import { setIsAuthenticated } from '../../../store/slices/authSlice';
import { UiImage } from '../../../ui/image/UiImage';
import { useNavigate } from 'react-router-dom';
import { getRoute } from '../../../utils/browser';
import { useTranslation } from '../../../hooks/use-translation';
import { setToken } from '../../../utils/token';
import i18n from '../../../utils/i18n';
import { loadScriptBody } from '../../../utils/load-scripts';

interface Props {
    setLoginIsInProgress: (value: boolean) => void;
}

export function LoginFacebook({ setLoginIsInProgress }: Props) {
    loadScriptBody(`
        window.fbAsyncInit = function() {
            FB.init({
            appId      : '300381225765985',
            xfbml      : true,
                version    : 'v17.0'
            });
            FB.AppEvents.logPageView();
        };

        (function(d, s, id){
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) {return;}
            js = d.createElement(s); js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));
    `);

    const [fbUserData, setFbUserData] = useState<FacebookLoginData>();
    const dispatch = useDispatch();
    const { translate } = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {
        auth();
    }, [fbUserData?.accessToken]);

    async function auth() {
        if (fbUserData) {
            setLoginIsInProgress(true);
            try {
                const response = await authWithSocial(fbUserData, 'FACEBOOK'); // extract to helpers
                const { locale, ...tokens } = response.data;
                setToken(tokens);
                dispatch(setIsAuthenticated(true));
                i18n.changeLanguage(locale);
                navigate(getRoute('profile'));
            } catch (err: unknown) {
                console.log(err);
            }
            setLoginIsInProgress(false);
        }
    }

    function fbLogin() {
        FB.login(
            (response) => {
                if (response.authResponse) {
                    const { accessToken, userID } = response.authResponse;
                    if (accessToken) {
                        setFbUserData({ accessToken, userId: userID });
                    }
                } else {
                    console.log('User cancelled login or did not fully authorize.');
                }
            },
            { scope: 'public_profile,email' },
        );
    }
    return (
        <div onClick={fbLogin}>
            <UiImage src="assets/login-facebook" />
            <span>{translate('Continue with', 'auth')} Facebook</span>
        </div>
    );
}
