import { Spin } from 'antd';
import React, { Suspense, useEffect } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import Wrapper from './styles';
import { PrivateRoutes } from './PrivateRoutes';
import { useSelector } from 'react-redux';
import { RootState, store } from '../../store/store';
import { useTranslation } from 'react-i18next';
import { setLocale } from '../../store/slices/commonSlice';
import { LoadingOutlined } from '@ant-design/icons';
import { getRoute } from '../../utils/browser';
import { Locale, PageRouteProps } from './types';

interface Props {
    routes: PageRouteProps[];
}

export const supportedLocales: Locale[] = ['en', 'ru', 'ee'];

export function Navigator({ routes }: Props) {
    const validRoutes = routes.filter((route) => !route.loginRequired);
    const privateRoutes = routes.filter((route) => route.loginRequired);
    const { locale, defaultLocale } = useSelector((state: RootState) => state.common);
    const { pathname } = useLocation();
    const { i18n } = useTranslation();
    const lang = pathname.substring(1, 3) || locale;
    const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);

    useEffect(() => {
        const newLocale = supportedLocales.includes(lang) ? lang : defaultLocale;
        i18n.changeLanguage(newLocale);
        store.dispatch(setLocale(newLocale));
    }, [locale]);

    function renderRoutes(routes?: PageRouteProps[]) {
        if (!routes?.length) return;

        return routes.map((route) => {
            return (
                <Route key={String(route.path)} path={route.path} {...route}>
                    {renderRoutes(route.routes)}
                </Route>
            );
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        }) as any;
    }

    return (
        <Wrapper>
            <Suspense
                fallback={
                    <div className="spinner-div">
                        <Spin size="large" indicator={<LoadingOutlined style={{ fontSize: 36 }} spin />} />
                    </div>
                }
            >
                <Routes>
                    {supportedLocales.map((lang) => {
                        return (
                            <Route path={`/${lang}`} key={lang}>
                                <Route element={<PrivateRoutes />}>{renderRoutes(privateRoutes)}</Route>
                                {renderRoutes(validRoutes)}
                            </Route>
                        );
                    })}
                    <Route path="/" element={<Navigate replace to={`/${lang}`} />} />
                    <Route path="*" element={<Navigate replace to={isAuthenticated ? getRoute('profile') : '/'} />} />
                </Routes>
            </Suspense>
        </Wrapper>
    );
}
