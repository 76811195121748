import React, { useEffect, useState } from 'react';
import Container from '../../container/Container';
import { App, Card, Modal, Space, Spin, Upload, UploadProps } from 'antd';
import { getRoute } from '../../../utils/browser';
import { useNavigate } from 'react-router-dom';
import { ArrowLeftOutlined, RetweetOutlined, UserOutlined } from '@ant-design/icons';
import Wrapper from './styles';
import { ProfileForm } from './form/ProfileForm';
import { UiButton } from '../../../ui/button/UiButton';
import { UiImage } from '../../../ui/image/UiImage';
import { useTranslation } from '../../../hooks/use-translation';
import { DeleteAccount } from './delete-account/DeleteAccount';
import { RootState, store } from '../../../store/store';
import { RcFile } from 'antd/es/upload';
import { refreshToken } from '../../../api/auth';
import axios from '../../../utils/axios';
import { setProfile, setProfilePicture } from '../../../store/slices/profileSlice';
import { useSelector } from 'react-redux';

export function ProfileEdit() {
    const navigate = useNavigate();
    const [isUnsavedDataModalOpen, setIsUnsavedDataModalOpen] = useState(false);
    const { translate } = useTranslation();
    const { message } = App.useApp();
    const [isImageLoading, setIsImageLoading] = useState(false);
    const userProfile = useSelector((state: RootState) => state.profile.userProfile);
    const [userProfilePicture, setUserProfilePicture] = useState(userProfile?.profilePicture);

    const props: UploadProps = {
        name: 'profile_image',
        showUploadList: false,
        beforeUpload,
        customRequest,
    };

    useEffect(() => {
        init();
    }, []);

    async function init() {
        const profile = await axios.get('/account/profile');
        store.dispatch(setProfile(profile.data));
        setUserProfilePicture(profile.data.profile_picture);
    }

    function navigateToProfile() {
        navigate(getRoute('profile'));
    }

    async function beforeUpload(file: RcFile) {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('Image must smaller than 2MB!');
        }
        await refreshToken();
        return isJpgOrPng && isLt2M;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async function customRequest(options: any) {
        const data = new FormData();
        data.append('profile_image', options.file);
        const config = {
            headers: {
                'content-type': 'multipart/form-data;',
            },
        };
        try {
            setIsImageLoading(true);
            const resp = await axios.post('/account/profile/upload-image', data, config);
            if (resp) {
                message.success(`File uploaded successfully`);
                setUserProfilePicture(resp.data.profileImage);
                store.dispatch(setProfilePicture(resp.data.profileImage));
            }
        } catch (err) {
            console.log(err);
            message.error(`There was a problem with file upload`);
        }
        setIsImageLoading(false);
    }

    async function deleteProfileImage() {
        setIsImageLoading(true);
        try {
            await axios.post('/account/profile/delete-profile-image');
            message.success(`Image removed successfully`);
            setUserProfilePicture('');
            store.dispatch(setProfilePicture(undefined));
        } catch (err) {
            console.log(err);
            message.error(`There was a problem with image deletion`);
        }
        setIsImageLoading(false);
    }

    return (
        <Container>
            <Wrapper profileimage={userProfilePicture}>
                <Space direction="vertical">
                    <Card
                        title={
                            <UiButton type="link" className="back-btn" onClick={navigateToProfile}>
                                <ArrowLeftOutlined /> {translate('Back to profile', 'profile.edit')}
                            </UiButton>
                        }
                    >
                        <div className="profile-card">
                            <div className="profile-wrapper">
                                <div className="profile-image">
                                    {isImageLoading && <Spin size="large" />}
                                    {!userProfilePicture && <UserOutlined className="default-profile" />}
                                </div>
                                <div className="image-actions">
                                    <Upload {...props}>
                                        <UiButton type="primary">{translate('Upload photo', 'profile.edit')}</UiButton>
                                    </Upload>

                                    <UiButton type="danger" onClick={deleteProfileImage}>
                                        {translate('Delete photo', 'profile.edit')}
                                    </UiButton>
                                </div>
                                <UiButton
                                    className="change-password"
                                    onClick={() => {
                                        navigate(getRoute('settings/change-password'));
                                    }}
                                >
                                    <RetweetOutlined />
                                    {translate('Change password', 'profile.edit')}
                                </UiButton>
                            </div>

                            <ProfileForm />
                        </div>
                    </Card>

                    <DeleteAccount />
                </Space>
                <Modal open={isUnsavedDataModalOpen} onCancel={() => setIsUnsavedDataModalOpen(false)} getContainer={false} footer={false}>
                    <UiImage src="assets/unsaved-data-modal-hero" />
                    <>{translate('Modified data is not saved', 'profile.edit')}</>
                    <UiButton type="success">{translate('Save settings & go back', 'profile.edit')}</UiButton>
                    <UiButton onClick={() => navigateToProfile()}>{translate('Go to profile', 'profile.edit')}</UiButton>
                </Modal>
            </Wrapper>
        </Container>
    );
}
