import React, { useEffect, useRef, useState } from 'react';
import Wrapper from './styles';
import Container from '../../container/Container';
import { Alert, Card, Col, Input, InputRef, Row, Spin, Typography } from 'antd';
import { useTranslation } from '../../../hooks/use-translation';
import { authWithEmailPassword } from '../../../api/auth';
import { RootState, store } from '../../../store/store';
import { setIsAuthenticated } from '../../../store/slices/authSlice';
import { Link, useNavigate } from 'react-router-dom';
import { getHeightToFitInWindow, getRoute } from '../../../utils/browser';
import { setIsFooterHidden } from '../../../store/slices/footerSlice';
import { UiImage } from '../../../ui/image/UiImage';
import { UiButton } from '../../../ui/button/UiButton';
import { useSelector } from 'react-redux';
import { ArrowLeftOutlined, LoadingOutlined } from '@ant-design/icons';
import { LoginHint } from '../hint/Hint';
import { setToken } from '../../../utils/token';
import i18n from '../../../utils/i18n';
import { AxiosError } from 'axios';

export function LoginEmailAndPassword() {
    const [pageHeight, setPageHeight] = useState(0);
    const email = useSelector((state: RootState) => state.auth.preFilledEmail);
    const { translate } = useTranslation();
    const passwordRef = useRef<InputRef>(null);
    const [isLoginInProgress, setIsLoginInProgress] = useState(false);
    const [loginErrorMessage, setLoginErrorMessage] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        store.dispatch(setIsFooterHidden(true));
        const heightToFitWindow = getHeightToFitInWindow('.login');
        setPageHeight(heightToFitWindow);

        return () => {
            store.dispatch(setIsFooterHidden(false));
        };
    }, []);

    async function loginWithEmailPassword() {
        setIsLoginInProgress(true);
        setLoginErrorMessage('');
        const password = passwordRef.current?.input?.value || '';
        try {
            const authData = await authWithEmailPassword(email as string, password);
            const { locale, ...tokens } = authData;
            setToken(tokens);
            store.dispatch(setIsAuthenticated(true));
            i18n.changeLanguage(locale);
        } catch (err: unknown) {
            const message = (err as AxiosError<{ message: string }>).response?.data.message ?? '';
            setLoginErrorMessage(message);
        }
        setIsLoginInProgress(false);
    }

    function onPressEnter(key: string) {
        if (key === 'Enter') {
            loginWithEmailPassword();
        }
    }

    return (
        <Container>
            <Wrapper height={pageHeight} className="login">
                <Card>
                    <ArrowLeftOutlined onClick={() => navigate(getRoute('login'))} className="back-btn" />
                    <UiImage src="assets/login_hero" />
                    <Typography.Title level={2}>{translate('Welcome back', 'auth')}</Typography.Title>
                    {loginErrorMessage && <Alert message={translate(loginErrorMessage, 'auth')} type="error" />}
                    <Input
                        autoFocus
                        size="large"
                        placeholder={translate('Password', 'auth')}
                        type="password"
                        ref={passwordRef}
                        onKeyDown={(element) => onPressEnter(element.key)}
                    />
                    <Link className="reset-password" to={getRoute('reset-password')}>
                        {translate('Reset password', 'auth')}
                    </Link>
                    <UiButton type="secondary" className="login-btn" size="large" onClick={loginWithEmailPassword}>
                        {translate('Login', 'auth')}
                    </UiButton>
                    <LoginHint />
                    {isLoginInProgress && (
                        <Row justify="center">
                            <Col>
                                <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}></Spin>
                            </Col>
                        </Row>
                    )}
                </Card>
            </Wrapper>
        </Container>
    );
}
