import axios from '../utils/axios';

interface WithdrawRequest {
    amount: number;
    status: 'PENDING' | 'APPROVED' | 'DENIED' | 'SETTLED';
    id: string;
    created_at: Date;
    comment: string | null;
}

export const getUserWithdrawRequests = async () => {
    try {
        const response = await axios.get<WithdrawRequest[]>(`/withdraw/requests`);
        return response.data;
    } catch (err) {
        console.log(err);
        //TODO: display error message
        return [];
    }
};

export const createWithdrawRequest = async (amount: number) => {
    try {
        await axios.post(`/withdraw/create-request`, { amount });
    } catch (err) {
        console.log(err);
        //TODO: display error message
    }
};
